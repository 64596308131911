/** Fonts **/

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./fonts/Poppins/Poppins-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'), url('./fonts/Poppins/Poppins-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins'; 
  font-weight: 500;
  src: local('Poppins'), url('./fonts/Poppins/Poppins-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'), url('./fonts/Poppins/Poppins-LightItalic.ttf') format('opentype');
}

** {
  font-family: Poppins, Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

html {
  height: 100%;
}

body {
  background-color: var(--background-color) !important;
  height: 100%;
  font-family: Poppins, Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.button:active, 
 button:active,
.button:focus, 
 button:focus,
.button:hover, 
 button:hover{
    border:none;
    outline:none;
}

p{
  text-align: left;
  margin-bottom: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  background-color: var(--background-color);
}