.solid-btn {
  min-width: 100%;
  height: 50px;
}

.btn-primary {
  background-color: #8a51f6;
  border-radius: 6px;
  border:none;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.btn-primary:active {
  background-color: #8a51f6;
  border-radius: 6px;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff !important;
}

.btn-primary:hover {
  background-color: #533194;
}
